import React, { useState } from "react";
import "../components/blogPage/style.scss";
import Layout from "../components/Layout";
import data from "../components/blogPage/data";
import { graphql, Link } from "gatsby";

// export default function Temp() {
//   return null;
// }

export default function BlogPage(props) {
  // const nodes = props.data.allMarkdownRemark.nodes || [];

  const [searchQuery, setSearchQuery] = useState("");

  // const filteredResults = nodes.filter((item) => {
  //   return (
  //     item.frontmatter.title.toLowerCase() +
  //     " " +
  //     item.frontmatter.date.toString().toLowerCase()
  //   ).includes(searchQuery.toLowerCase());
  // });
  const filteredResults = [];
  function handleSearchQueryChange(e) {
    setSearchQuery(e.target.value);
  }
  return <div></div>;
  return (
    <Layout>
      <div className="blog">
        <h1>Blog</h1>

        <div className="blog__searchcontainer">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            className="blog__search"
            placeholder="Search"
            onChange={handleSearchQueryChange}
          ></input>
        </div>

        <div className="blog__container">
          {filteredResults.map((item) => {
            const date = item.frontmatter.date.split(",")[0];

            return (
              <div className="blog__item">
                <div className="blog__item__date">
                  <img src={item.frontmatter.authorImage}></img>
                </div>
                <Link to={`/blog${item.fields.slug}`}>
                  <h3>{item.frontmatter.title}</h3>
                  <small>
                    <strong>
                      by {item.frontmatter.authorName} | {item.frontmatter.date}
                    </strong>
                  </small>
                  <p>{item.frontmatter.description}</p>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt
//         fields {
//           slug
//         }
//         frontmatter {
//           date(formatString: "MMMM DD, YYYY")
//           title
//           description
//         }
//       }
//     }
//   }
// `;
